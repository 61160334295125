import { readEnv } from "@/ts/util/readEnv";


export function isDebugEnabled(): boolean {
    // Server-side.
    if (typeof window === "undefined")
        return readEnv("NODE_ENV") === "development";

    // Client-side.
    const hostname = window.location.hostname;
    return hostname === "localhost" || hostname === "dev.royalur.net";
}


export function logDebug(...args: any[]) {
    if (isDebugEnabled()) {
        console.log(...args);
    }
}
