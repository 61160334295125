import { Generated, Insertable, Selectable, Updateable } from "kysely";


export type RowID = string;
export type Int4 = number;
export type Float4 = number;
export type Float8 = number;


export interface Database {
    royal_user: DBUserTable;
    royal_user_session: DBUserSessionTable;
    royal_game_player: DBGamePlayerTable;
    royal_game: DBGameTable;
    game_analysis: DBGameAnalysisTable;
    ongoing_lobby: DBOngoingLobbyTable;
    royal_token: DBTokenTable;
    email_type: DBEmailTypeTable;
    queued_email: DBQueuedEmailTable;
    email_delivery: DBEmailDeliveryTable;
    email_suppression_list: DBEmailSuppressionListTable;
    royal_email_list: DBRoyalEmailListTable;
    personalised_email_list: DBPersonalisedEmailListTable;
    stripe_customer: DBStripeCustomerTable;
    membur_subscription: DBMemburSubscriptionTable;
    user_game_preferences: DBUserGamePreferencesTable;
    user_stats: DBUserStatsTable;
    monthly_user_stats: DBMonthlyUserStatsTable;
}


export interface DBUserTable {
    id: Generated<RowID>;
    public_id: string;
    cognito_id: string;
    cognito_username: string | null;
    email: string;
    canonical_email: string;
    created_at: Generated<Date>;
    delete_at: Date | null;
    agreed_to_policies: boolean;
    username: string | null;
    display_name: string | null;
    memburship_end_at: Date | null;
    has_role_team: Generated<boolean>;
    has_role_admin: Generated<boolean>;
    complete: Generated<boolean>;
}

export const DBUserColumns: (keyof DBUserTable)[] = [
    "id", "public_id", "cognito_id", "cognito_username", "email", "created_at",
    "delete_at", "agreed_to_policies", "username", "display_name",
    "memburship_end_at", "has_role_team", "has_role_admin", "complete",
];


export type DBUser = Selectable<DBUserTable>;
export type DBNewUser = Insertable<DBUserTable>;
export type DBUserUpdate = Updateable<DBUserTable>;


export interface DBUserSessionTable {
    id: Generated<RowID>;
    public_id: string;
    user_id: RowID;
    expires_at: Date;
}

export type DBUserSession = Selectable<DBUserSessionTable>;
export type NewDBUserSession = Insertable<DBUserSessionTable>;
export type DBUserSessionUpdate = Updateable<DBUserSessionTable>;


export interface DBGamePlayerTable {
    id: Generated<RowID>;
    user_id: RowID | null;
    bot_id: string | null;
}

export type DBGamePlayer = Selectable<DBGamePlayerTable>;
export type NewDBGamePlayer = Insertable<DBGamePlayerTable>;
export type DBGamePlayerUpdate = Updateable<DBGamePlayerTable>;


export interface DBGameTable {
    id: Generated<RowID>;
    public_id: string;
    trusted: boolean;
    game_mode: string;
    settings_id: string;
    bot_type_id: string | null;
    end_reason_id: string;
    is_ending_player_light: boolean | null;
    light_player_id: RowID | null;
    dark_player_id: RowID | null;
    did_light_win: boolean;
    contents: string | undefined;
    started_at: Generated<Date>;
    ended_at: Generated<Date>;
    created_at: Generated<Date>;
    game_analysis_id: RowID | null;
}

export const DBGameColumns: (keyof DBGameTable)[] = [
    "id", "public_id", "trusted", "game_mode", "settings_id", "bot_type_id",
    "end_reason_id", "is_ending_player_light", "light_player_id", "dark_player_id",
    "did_light_win", "contents", "started_at", "ended_at", "created_at",
    "game_analysis_id",
];

export type DBGame = Selectable<DBGameTable>;
export type NewDBGame = Insertable<DBGameTable>;
export type DBGameUpdate = Updateable<DBGameTable>;


export interface DBGameAnalysisTable {
    id: Generated<RowID>;
    game_id: RowID;
    light_move_count: Int4;
    dark_move_count: Int4;
    light_mean_roll_delta: Float8;
    dark_mean_roll_delta: Float8;
    light_mean_move_delta: Float8;
    dark_mean_move_delta: Float8;
    created_at: Generated<Date>;
}

export type DBGameAnalysis = Selectable<DBGameAnalysisTable>;
export type NewDBGameAnalysis = Insertable<DBGameAnalysisTable>;
export type DBGameAnalysisUpdate = Updateable<DBGameAnalysisTable>;


export interface DBOngoingLobbyTable {
    id: Generated<RowID>;
    public_id: string;
    version: Int4;
    trusted: boolean;
    game_mode: string;
    settings_id: string;
    bot_type_id: string | null;
    light_player_id: RowID | null;
    dark_player_id: RowID | null;
    contents: string | undefined;
    last_updated_at: Generated<Date>;
    created_at: Generated<Date>;
}

export type DBOngoingLobby = Selectable<DBOngoingLobbyTable>;
export type NewDBOngoingLobby = Insertable<DBOngoingLobbyTable>;
export type DBOngoingLobbyUpdate = Updateable<DBOngoingLobbyTable>;


export interface DBTokenTable {
    id: Generated<RowID>;
    token: string;
    value: string;
    scope: string;
    expires_at: Date;
}

export type DBToken = Selectable<DBTokenTable>;
export type NewDBToken = Insertable<DBTokenTable>;
export type DBTokenUpdate = Updateable<DBTokenTable>;


export type UnsubscribeTarget = "personalised" | "royal";
export const UNSUB_TARGET_PERSONALISED: UnsubscribeTarget = "personalised";
export const UNSUB_TARGET_ROYAL_LIST: UnsubscribeTarget = "royal";

export function checkUnsubscribeTarget(target: string): UnsubscribeTarget {
    if (target === UNSUB_TARGET_PERSONALISED)
        return UNSUB_TARGET_PERSONALISED;
    if (target == UNSUB_TARGET_ROYAL_LIST)
        return UNSUB_TARGET_ROYAL_LIST;

    throw new Error(`Unknown unsubscribe target ${target}`);
}


export interface DBEmailTypeTable {
    id: Generated<RowID>;
    name: string;
    desc: string;
    source_email: string;
    unsubscribe_target: string;
    created_at: Generated<Date>;
}

export type DBEmailType = Selectable<DBEmailTypeTable>;
export type NewDBEmailType = Insertable<DBEmailTypeTable>;
export type DBEmailTypeUpdate = Updateable<DBEmailTypeTable>;


export interface DBQueuedEmailTable {
    id: Generated<RowID>;
    public_id: string;
    email_type_id: RowID;
    recipient_name: string;
    recipient_email: string;
    recipient_canonical_email: string;
    recipient_user_id: RowID | null;
    mjml: string;
    created_at: Generated<Date>;
}

export type DBQueuedEmail = Selectable<DBQueuedEmailTable>;
export type NewDBQueuedEmailTable = Insertable<DBQueuedEmailTable>;
export type DBQueuedEmailUpdate = Updateable<DBQueuedEmailTable>;


export interface DBEmailDeliveryTable {
    id: Generated<RowID>;
    public_id: string;
    message_id: string;
    email_type_id: RowID;
    recipient_name: string;
    recipient_email: string;
    recipient_canonical_email: string;
    recipient_user_id: RowID | null;
    status: Generated<string | null>;
    status_received_at: Generated<Date | null>;
    status_data: unknown | null;
    created_at: Generated<Date>;
}

export type DBEmailDelivery = Selectable<DBEmailDeliveryTable>;
export type NewDBEmailDelivery = Insertable<DBEmailDeliveryTable>;
export type DBEmailDeliveryUpdate = Updateable<DBEmailDeliveryTable>;


export interface DBEmailSuppressionListTable {
    id: Generated<RowID>;
    email: string;
    canonical_email: string;
    suppression_reason: string;
    reason_email_id: RowID | null;
    created_at: Generated<Date>;
}

export type DBEmailSuppression = Selectable<DBEmailSuppressionListTable>;
export type NewDBEmailSuppression = Insertable<DBEmailSuppressionListTable>;
export type DBEmailSuppressionUpdate = Updateable<DBEmailSuppressionListTable>;


export interface DBRoyalEmailListTable {
    id: Generated<RowID>;
    email: string;
    canonical_email: string;
    user_id: RowID | null;
    name: string | null;
    subscribe_source: string;
    unsubscribed_at: Generated<Date | null>;
    unsubscribe_reason: Generated<string | null>;
    created_at: Generated<Date>;
}

export const DBRoyalEmailListTableColumns: (keyof DBRoyalEmailListTable)[] = [
    "id", "email", "user_id", "name", "subscribe_source",
    "unsubscribed_at", "unsubscribe_reason", "created_at",
];

export type DBRoyalEmail = Selectable<DBRoyalEmailListTable>;
export type NewDBRoyalEmail = Insertable<DBRoyalEmailListTable>;
export type DBRoyalEmailUpdate = Updateable<DBRoyalEmailListTable>;


export interface DBPersonalisedEmailListTable {
    id: Generated<RowID>;
    user_id: RowID;
    unsubscribed_at: Generated<Date>;
    unsubscribe_reason: Generated<string>;
    created_at: Generated<Date>;
}

export type DBPersonalisedEmail = Selectable<DBPersonalisedEmailListTable>;
export type NewDBPersonalisedEmail = Insertable<DBPersonalisedEmailListTable>;
export type DBPersonalisedEmailUpdate = Updateable<DBPersonalisedEmailListTable>;


export interface DBStripeCustomerTable {
    id: Generated<RowID>;
    user_id: RowID;
    external_stripe_customer_id: string;
    memburship_subscription_id: string | null;
}

export type DBStripeCustomer = Selectable<DBStripeCustomerTable>;
export type NewDBStripeCustomer = Insertable<DBStripeCustomerTable>;
export type DBStripeCustomerUpdate = Updateable<DBStripeCustomerTable>;


export interface DBMemburSubscriptionTable {
    id: Generated<RowID>;
    user_id: RowID;
    external_stripe_sub_id: string;
    ends_at: Date;
    status: string;
    created_at: Generated<Date>;
}

export type DBMemburSubscription = Selectable<DBMemburSubscriptionTable>;
export type NewDBMemburSubscription = Insertable<DBMemburSubscriptionTable>;
export type DBMemburSubscriptionUpdate = Updateable<DBMemburSubscriptionTable>;


export class DBNameColour {
    public static readonly GRAY = new DBNameColour(0, "gray");
    public static readonly RED = new DBNameColour(1, "red");
    public static readonly GREEN = new DBNameColour(2, "green");
    public static readonly BLUE = new DBNameColour(3, "blue");
    public static readonly GOLD = new DBNameColour(4, "gold");
    public static readonly ORANGE = new DBNameColour(5, "orange");
    public static readonly PINK = new DBNameColour(6, "pink");

    static values(): DBNameColour[] {
        return [
            DBNameColour.GRAY,
            DBNameColour.RED,
            DBNameColour.GREEN,
            DBNameColour.BLUE,
            DBNameColour.GOLD,
            DBNameColour.ORANGE,
            DBNameColour.PINK,
        ];
    }

    private readonly id: number;
    private readonly apiID: string;

    constructor(id: number, apiID: string) {
        this.id = id;
        this.apiID = apiID;
    }

    getID(): number {
        return this.id;
    }

    getApiID(): string {
        return this.apiID;
    }

    static findByID(id: number, defaultColour?: DBNameColour): DBNameColour {
        for (const colour of DBNameColour.values()) {
            if (colour.id === id)
                return colour;
        }
        if (defaultColour)
            return defaultColour;

        throw new Error(`Unknown name colour ID, ${id}`);
    }

    static findByApiID(apiID: string): DBNameColour {
        for (const colour of DBNameColour.values()) {
            if (colour.apiID === apiID)
                return colour;
        }
        throw new Error(`Unknown name colour API ID, ${apiID}`);
    }
}


export interface DBUserGamePreferencesTable {
    id: Generated<RowID>;
    user_id: RowID;

    // DBNameColour ID.
    name_colour: Generated<Int4>;

    // 0.0 -> 100.0
    sound_volume: Generated<number>;

    // 0.0 -> 100.0
    music_volume: Generated<number>;
    hide_music_controls: Generated<boolean>;

    auto_select_single_move_option: Generated<boolean>;
    show_move_guidelines: Generated<boolean>;
    disabled_reactions: Generated<boolean>;

    dice_style: Generated<string>;
    auto_dice_rolls: Generated<boolean>;
}

export const DBUserGamePreferencesColumns: (keyof DBUserGamePreferencesTable)[] = [
    "id", "user_id", "name_colour", "sound_volume", "music_volume", "hide_music_controls",
    "auto_select_single_move_option", "show_move_guidelines", "disabled_reactions",
    "dice_style", "auto_dice_rolls",
];

export type DBUserGamePreferences = Selectable<DBUserGamePreferencesTable>;
export type NewDBUserGamePreferences = Insertable<DBUserGamePreferencesTable>;
export type DBUserGamePreferencesUpdate = Updateable<DBUserGamePreferencesTable>;


export interface DBUserStatsTable {
    id: Generated<RowID>;
    user_id: RowID;
    moves: Generated<Int4>;
    captures: Generated<Int4>;
    lands_on_rosettes: Generated<Int4>;
    scored_pieces: Generated<Int4>;
    longest_streak: Generated<Int4>;
    longest_streak_game: Generated<string>;

    roll_4d2_0_count: Generated<Int4>;
    roll_4d2_1_count: Generated<Int4>;
    roll_4d2_2_count: Generated<Int4>;
    roll_4d2_3_count: Generated<Int4>;
    roll_4d2_4_count: Generated<Int4>;
    roll_4d2_count: Generated<Int4>;

    online_finkel_wins: Generated<Int4>;
    online_finkel_losses: Generated<Int4>;
    online_blitz_wins: Generated<Int4>;
    online_blitz_losses: Generated<Int4>;
    online_masters_wins: Generated<Int4>;
    online_masters_losses: Generated<Int4>;
    online_wins: Generated<Int4>;

    online_resigned_games: Generated<Int4>;
    online_left_games: Generated<Int4>;
    online_left_b4_begin_games: Generated<Int4>;

    friend_finkel_wins: Generated<Int4>;
    friend_finkel_losses: Generated<Int4>;
    friend_blitz_wins: Generated<Int4>;
    friend_blitz_losses: Generated<Int4>;
    friend_masters_wins: Generated<Int4>;
    friend_masters_losses: Generated<Int4>;

    computer_finkel_wins: Generated<Int4>;
    computer_finkel_losses: Generated<Int4>;
    computer_blitz_wins: Generated<Int4>;
    computer_blitz_losses: Generated<Int4>;
    computer_masters_wins: Generated<Int4>;
    computer_masters_losses: Generated<Int4>;

    panda_finkel_wins: Generated<Int4>;
    panda_finkel_losses: Generated<Int4>;
    panda_blitz_wins: Generated<Int4>;
    panda_blitz_losses: Generated<Int4>;
    panda_masters_wins: Generated<Int4>;
    panda_masters_losses: Generated<Int4>;
    panda_wins: Generated<Int4>;

    custom_games: Generated<Int4>;
    games: Generated<Int4>;
}

export const DBUserStatsColumns: (keyof DBUserStatsTable)[] = [
    "id", "user_id", "moves", "captures", "lands_on_rosettes",
    "scored_pieces", "longest_streak", "longest_streak_game",

    "roll_4d2_0_count", "roll_4d2_1_count", "roll_4d2_2_count",
    "roll_4d2_3_count", "roll_4d2_4_count", "roll_4d2_count",

    "online_finkel_wins", "online_finkel_losses",
    "online_blitz_wins", "online_blitz_losses",
    "online_masters_wins", "online_masters_losses",
    "online_wins",

    "online_resigned_games", "online_left_games",
    "online_left_b4_begin_games",

    "friend_finkel_wins", "friend_finkel_losses",
    "friend_blitz_wins", "friend_blitz_losses",
    "friend_masters_wins", "friend_masters_losses",

    "computer_finkel_wins", "computer_finkel_losses",
    "computer_blitz_wins", "computer_blitz_losses",
    "computer_masters_wins", "computer_masters_losses",

    "panda_finkel_wins", "panda_finkel_losses",
    "panda_blitz_wins", "panda_blitz_losses",
    "panda_masters_wins", "panda_masters_losses",
    "panda_wins",

    "custom_games", "games",
];

export type DBUserStats = Selectable<DBUserStatsTable>;
export type NewDBUserStats = Insertable<DBUserStatsTable>;
export type DBUserStatsUpdate = Updateable<DBUserStatsTable>;


export interface DBMonthlyUserStatsTable {
    id: Generated<RowID>;
    user_id: RowID;
    year_month: Int4;

    online_finkel_wins: Generated<Int4>;
    online_finkel_losses: Generated<Int4>;
    online_blitz_wins: Generated<Int4>;
    online_blitz_losses: Generated<Int4>;
    online_masters_wins: Generated<Int4>;
    online_masters_losses: Generated<Int4>;
    online_wins: Generated<Int4>;

    online_resigned_games: Generated<Int4>;
    online_left_games: Generated<Int4>;
    online_left_b4_begin_games: Generated<Int4>;

    friend_finkel_wins: Generated<Int4>;
    friend_finkel_losses: Generated<Int4>;
    friend_blitz_wins: Generated<Int4>;
    friend_blitz_losses: Generated<Int4>;
    friend_masters_wins: Generated<Int4>;
    friend_masters_losses: Generated<Int4>;

    computer_finkel_wins: Generated<Int4>;
    computer_finkel_losses: Generated<Int4>;
    computer_blitz_wins: Generated<Int4>;
    computer_blitz_losses: Generated<Int4>;
    computer_masters_wins: Generated<Int4>;
    computer_masters_losses: Generated<Int4>;

    panda_finkel_wins: Generated<Int4>;
    panda_finkel_losses: Generated<Int4>;
    panda_blitz_wins: Generated<Int4>;
    panda_blitz_losses: Generated<Int4>;
    panda_masters_wins: Generated<Int4>;
    panda_masters_losses: Generated<Int4>;
    panda_wins: Generated<Int4>;

    custom_games: Generated<Int4>;
    games: Generated<Int4>;
}

export const DBMonthlyUserStatsColumns: (keyof DBMonthlyUserStatsTable)[] = [
    "id", "user_id", "year_month",

    "online_finkel_wins", "online_finkel_losses",
    "online_blitz_wins", "online_blitz_losses",
    "online_masters_wins", "online_masters_losses",
    "online_wins",

    "online_resigned_games", "online_left_games",
    "online_left_b4_begin_games",

    "friend_finkel_wins", "friend_finkel_losses",
    "friend_blitz_wins", "friend_blitz_losses",
    "friend_masters_wins", "friend_masters_losses",

    "computer_finkel_wins", "computer_finkel_losses",
    "computer_blitz_wins", "computer_blitz_losses",
    "computer_masters_wins", "computer_masters_losses",

    "panda_finkel_wins", "panda_finkel_losses",
    "panda_blitz_wins", "panda_blitz_losses",
    "panda_masters_wins", "panda_masters_losses",
    "panda_wins",

    "custom_games", "games",
];

export type DBMonthlyUserStats = Selectable<DBMonthlyUserStatsTable>;
export type NewDBMonthlyUserStats = Insertable<DBMonthlyUserStatsTable>;
export type DBMonthlyUserStatsUpdate = Updateable<DBMonthlyUserStatsTable>;
