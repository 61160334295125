import { LeaderboardGrouping } from "@/ts/business/api/leaderboard/LeaderboardGrouping";
import { LeaderboardMetric } from "@/ts/business/api/leaderboard/LeaderboardMetric";
import { LeaderboardPeriod } from "@/ts/business/api/leaderboard/LeaderboardPeriod";
import { formatMonth } from "@/ts/util/formatting";


export class LeaderboardType {
    public readonly period: LeaderboardPeriod;
    public readonly yearMonth: number | null;
    public readonly grouping: LeaderboardGrouping;
    public readonly metric: LeaderboardMetric;

    constructor(
        period: LeaderboardPeriod,
        yearMonth: number | null,
        grouping: LeaderboardGrouping,
        metric: LeaderboardMetric,
    ) {
        if (yearMonth === null && period !== LeaderboardPeriod.ALL_TIME)
            throw new Error(`yearMonth is required when period is ${period.getID()}`);

        this.period = period;
        this.yearMonth = yearMonth;
        this.grouping = grouping;
        this.metric = metric;
    }

    static calculateYearMonth(date: Date): number {
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1;
        return 100 * year + month;
    }

    createID(): string {
        return `${this.period.getID()}__${this.grouping.getID()}__${this.metric.getID()}`;
    }

    createDesc(): string {
        if (this.period === LeaderboardPeriod.ALL_TIME) {
            if (this.grouping === LeaderboardGrouping.OVERALL) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return "Online wins";
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return "Wins vs. Panda";
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else if (this.grouping === LeaderboardGrouping.FINKEL) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return "Online Finkel wins";
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return "Finkel wins vs. Panda";
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else if (this.grouping === LeaderboardGrouping.BLITZ) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return "Online blitz wins";
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return "Blitz wins vs. Panda";
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else if (this.grouping === LeaderboardGrouping.MASTERS) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return "Online Masters wins";
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return "Masters wins vs. Panda";
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else {
                throw new Error(`Unknown leaderboard grouping: ${this.grouping.getID()}`);
            }

        } else if (this.period === LeaderboardPeriod.MONTHLY) {
            if (this.yearMonth === null)
                throw new Error("yearMonth is required for monthly period");

            const month = formatMonth(this.yearMonth % 100 - 1);
            if (this.grouping === LeaderboardGrouping.OVERALL) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return `Online wins in ${month}`;
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return `Wins vs. Panda in ${month}`;
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else if (this.grouping === LeaderboardGrouping.FINKEL) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return `Online Finkel wins in ${month}`;
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return `Finkel wins vs. Panda in ${month}`;
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else if (this.grouping === LeaderboardGrouping.BLITZ) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return `Online blitz wins in ${month}`;
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return `Blitz wins vs. Panda in ${month}`;
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else if (this.grouping === LeaderboardGrouping.MASTERS) {
                if (this.metric === LeaderboardMetric.ONLINE_WINS) {
                    return `Online Masters wins in ${month}`;
                } else if (this.metric === LeaderboardMetric.PANDA_WINS) {
                    return `Masters wins vs. Panda in ${month}`;
                } else {
                    throw new Error(`Unknown leaderboard metric: ${this.metric.getID()}`);
                }
            } else {
                throw new Error(`Unknown leaderboard grouping: ${this.grouping.getID()}`);
            }
        } else {
            throw new Error(`Unknown leaderboard period: ${this.period.getID()}`);
        }
    }
}
