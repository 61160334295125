import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Move } from "@/ts/royalur/model/Move";
import { DescribePlayerNames, getDescribePlayerName } from "@/ts/royalur/rules/state/GameState";


/**
 * A game state that represents a roll that was made in a game.
 */
export class RolledGameState extends ActionGameState {
    private readonly roll: Roll;
    private readonly availableMoves: Move[];

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        turn: PlayerType,
        roll: Roll,
        availableMoves: Move[],
    ) {
        super(board, lightPlayer, darkPlayer, turn);
        this.roll = roll;
        this.availableMoves = availableMoves;
    }

    getRoll(): Roll {
        return this.roll;
    }

    getAvailableMoves(): Move[] {
        return this.availableMoves;
    }

    override describe(playerNames: DescribePlayerNames): string {
        const playerName = getDescribePlayerName(this.getTurn(), playerNames);

        let noMoves = "";
        if (this.availableMoves.length === 0) {
            if (this.roll.value() === 0) {
                noMoves = ", and had no moves";
            } else {
                noMoves = ", and all moves were blocked";
            }
        }
        return `${playerName} rolled ${this.roll.value()}${noMoves}`;
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof RolledGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return ActionGameState._baselineEquals(this, other)
            && this.roll.equals(other.roll)
            && Move.setEquals(this.availableMoves, other.availableMoves);
    }
}
