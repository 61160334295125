
/**
 * If bound1 and bound2 are provided, generates a random floating point
 * number in the range [bound1, bound2). Otherwise, generates a random
 * floating point number in the range [0, 1).
 */
export function rand(bound1?: number, bound2?: number): number {
    if (bound1 === undefined && bound2 === undefined)
        return Math.random();

    if (bound1 === undefined || bound2 === undefined)
        throw new Error("Only one of the bounds was provided");

    return bound1 + Math.random() * (bound2 - bound1);
}


/**
 * If bound1 and bound2 are both provided, generates a random
 * integer in the range [bound1, bound2). Otherwise, this generates
 * a random integer in the range [0, bound1).
 */
export function randInt(bound1: number, bound2?: number): number {
    if (bound2 === undefined) {
        bound2 = bound1;
        bound1 = 0.0;
    }
    return Math.floor(rand(bound1, bound2));
}

/**
 * Selects a random element from the given array.
 */
export function randElement<V>(array: V[]): V {
    if (array.length === 0)
        throw new Error("Array is empty");

    return array[randInt(array.length)];
}

/**
 * Generates a random boolean value.
 */
export function randBool(): boolean {
    return rand() < 0.5;
}

/**
 * Shuffles the given array.
 */
export function randShuffle<T>(array: T[]): void {
    let currentIndex = array.length;
    while (currentIndex > 0) {
        const randomIndex = randInt(currentIndex);
        currentIndex -= 1;

        const temp = array[randomIndex];
        array[randomIndex] = array[currentIndex];
        array[currentIndex] = temp;
    }
}


const ID_CHARS = "abcdefghijklmnopqrstuvwxyz0123456789";


/**
 * Generates a random ID made up of lowercase letters and numbers.
 */
export function randID(length?: number): string {
    if (length === undefined) {
        length = 16;
    }

    const content: string[] = [];
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * ID_CHARS.length);
        content.push(ID_CHARS[randomIndex]);
    }
    return content.join("");
}
