import { AnalyticsEvent } from "@/ts/business/analytics/AnalyticsEvent";
import {
    ANALYTICS_GAME_PREFIX,
    AnalyticsEventType,
} from "@/ts/business/analytics/AnalyticsEventType";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";
import { BotType } from "@/ts/business/game/BotType";


function generateEventAction(
    type: AnalyticsEventType,
    mode: GameMode | null,
    difficulty: BotType | undefined,
): string {
    const modeName = (mode ? mode.getName() : "unknown");
    let difficultySuffix = "";
    if (mode === GameMode.COMPUTER) {
        difficultySuffix = (difficulty ? `_${difficulty.getName()}` : "_unknown");
    }
    return `${type.getEventCategory()}_${modeName}${difficultySuffix}`;
}


function generateEventName(
    type: AnalyticsEventType,
    gameType: GameType | null,
): string {
    const typeName = (gameType ? gameType.getName() : "custom");
    return `${type.getEventCategory()}_${typeName}`;
}


export class GameAnalyticsEvent extends AnalyticsEvent {
    constructor(
        type: AnalyticsEventType,
        mode: GameMode | null,
        gameType: GameType | null,
        difficulty: BotType | undefined,
    ) {
        super(
            type,
            generateEventAction(type, mode, difficulty),
            generateEventName(type, gameType),
        );
        if (type.getPrefix() !== ANALYTICS_GAME_PREFIX)
            throw new Error(type.getEventCategory() + " is not a game event");
    }
}
