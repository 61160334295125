"use client";

import { useEffect } from "react";
import { AnalyticsEvent } from "@/ts/business/analytics/AnalyticsEvent";
import { logDebug } from "@/ts/business/debug";
import { AnalyticsEventType } from "@/ts/business/analytics/AnalyticsEventType";
import { GameAnalyticsEvent } from "@/ts/business/analytics/GameAnalyticsEvent";
import { GameMode } from "@/ts/business/game/GameMode";
import { AnalyticsProvider, LobbySettingsArg } from "@/ts/business/analytics/Analytics";
import { APIUser } from "@/ts/business/api/api_schema";
import { Milliseconds } from "@/ts/util/units";
import { getTimeMs } from "@/ts/util/utils";
import { readStorageJSON, writeStorageJSON } from "@/ts/util/storage";
import { usePathname } from "next/navigation";
import { addAnalyticsConsentListener } from "@/app_components/cookie/MoneytizerCMP";


type EventArray = (string | number)[];

declare global {
    interface Window {
        _paq?: EventArray[];
        clarity?: object;
    }
}


export class Matomo extends AnalyticsProvider {
    private initialised: boolean = false;
    private recordedUser: boolean = false;
    private lastEventTime: Milliseconds = -9999;

    static getURL(): string {
        const host = window.location.host.toLowerCase();

        if (host === "web2.royalur.net")
            return "https://matomo2.royalur.net/";

        return "https://matomo.royalur.net/";
    }

    isInitialised(): boolean {
        return this.initialised;
    }

    setInitialised(initialised: boolean) {
        this.initialised = initialised;
    }

    getPAQ(): EventArray[] {
        if (typeof window === "undefined")
            return [];

        if (!window._paq) {
            window._paq = [];
        }
        return window._paq;
    }

    recordUser(user: APIUser | null) {
        if (!user || this.recordedUser)
            return;

        this.recordedUser = true;
        this.getPAQ().push(["setUserId", user.username]);
    }

    recordEvent(event: AnalyticsEvent) {
        const time = getTimeMs();
        if (time - this.lastEventTime < 1000)
            return;

        this.lastEventTime = time;
        const eventArray: EventArray = [
            "trackEvent",
            event.getType().getEventCategory(),
            event.getAction(),
        ];

        const name = event.getName();
        if (name) {
            eventArray.push(name);
            const value = event.getValue();
            if (value) {
                eventArray.push(value);
            }
        }

        // Report the event to the Matomo analytics.
        logDebug("Analytics: Recording event [" + eventArray.join(", ") + "]");
        this.getPAQ().push(eventArray);
    }

    recordGameEvent(
        eventType: AnalyticsEventType,
        setupSettings: LobbySettingsArg,
    ) {
        const mode = setupSettings.getMode();
        const gameSettings = setupSettings.getGameType();
        const botType = setupSettings.getBotType() ?? undefined;
        if (mode === GameMode.COMPUTER && !botType)
            throw new Error("No difficulty provided with a computer game");

        this.recordEvent(new GameAnalyticsEvent(
            eventType, mode, gameSettings,
            (mode === GameMode.COMPUTER ? botType : undefined),
        ));
    }

    recordStartGame(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.START_GAME, setupSettings);
    }

    recordAbortGame(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.ABORT_GAME, setupSettings);
    }

    recordFinishGame(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.FINISH_GAME, setupSettings);
    }

    recordReviewGame(setupSettings: LobbySettingsArg, gameID: string) {
        // Try to only record the review action once.
        const rawReviewedIDs = readStorageJSON("reviewedGameIDs");
        let reviewedGameIDs: string[];

        if (rawReviewedIDs && Array.isArray(rawReviewedIDs)
            && rawReviewedIDs.every(x => typeof x === "string")) {

            reviewedGameIDs = rawReviewedIDs;
            if (reviewedGameIDs.includes(gameID))
                return;
        } else {
            reviewedGameIDs = [];
        }

        this.recordGameEvent(AnalyticsEventType.REVIEW_GAME, setupSettings);
        writeStorageJSON("reviewedGameIDs", [gameID, ...reviewedGameIDs]);
    }

    recordCreateLobby(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.CREATE_LOBBY, setupSettings);
    }

    recordSearchForLobby(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.SEARCH_FOR_LOBBY, setupSettings);
    }
}


export const matomo: Matomo = new Matomo();
let lastPathname: string | null = null;


function filterPathname(path: string): string {
    // Remove # and ? parts of path.
    return path.split("?")[0].replace(/#.*/, "");
}


function trackPageView(_paq: EventArray[], path: string | null) {
    if (!path)
        return;

    const pathname = filterPathname(path);

    if (pathname !== lastPathname) {
        lastPathname = pathname;
        _paq.push(["setCustomUrl", filterPathname(path)]);
        _paq.push(["setDocumentTitle", document.title]);
        _paq.push(["trackPageView"]);
    }
}


function tryInitMatomo(path: string | null): boolean {
    if (matomo.isInitialised())
        return false;

    matomo.setInitialised(true);
    const _paq = matomo.getPAQ();
    _paq.push(["requireCookieConsent"]);
    _paq.push(["enableLinkTracking"]);
    _paq.push(["enableHeartBeatTimer"]);
    trackPageView(_paq, path);

    const updateCookieConsent = (consented: boolean) => {
        if (consented) {
            _paq.push(["setCookieConsentGiven"]);
        } else {
            _paq.push(["forgetCookieConsentGiven"]);
        }
    };
    addAnalyticsConsentListener(updateCookieConsent);

    (function () {
        const matomoURL = Matomo.getURL();
        _paq.push(["setTrackerUrl", matomoURL + "matomo.php"]);
        _paq.push(["setSiteId", "1"]);
        const matomoScript = document.createElement("script");
        matomoScript.type = "text/javascript";
        matomoScript.async = true;
        matomoScript.src = matomoURL + "matomo.js";

        const scriptElems = document.getElementsByTagName("script");
        if (scriptElems.length > 0 && scriptElems[0].parentNode) {
            scriptElems[0].parentNode.insertBefore(matomoScript, scriptElems[0]);
        } else {
            document.body.appendChild(matomoScript);
        }
    })();
    return true;
}


export function MatomoProvider() {
    const path = usePathname();
    useEffect(() => {
        // Init tracks the page view itself.
        if (tryInitMatomo(path))
            return;

        // Wait to make sure the page title has been updated.
        setTimeout(() => {
            trackPageView(matomo.getPAQ(), path);
        });
    }, [path]);
    return <></>;
}
