

export function abs(num: number): number {
    return num >= 0 ? num : -num;
}

export function max(a: number, b: number): number {
    return a > b ? a : b;
}

export function min(a: number, b: number): number {
    return a < b ? a : b;
}

export function clamp(num: number, min: number, max: number): number {
    return (num < min ? min : (num > max ? max : num));
}

export function floor(num: number): number {
    return Math.floor(num);
}

export function ceil(num: number): number {
    return Math.ceil(num);
}

export function easeInOutSine(value: number): number {
    return (1 - Math.cos(value * Math.PI)) / 2;
}

export function easeOutSine(value: number): number {
    return Math.sin(value * 0.5 * Math.PI);
}

export function easeInSine(value: number): number {
    return 1 - easeOutSine(1 - value);
}

export function easeInQuad(value: number): number {
    return value * value;
}

export function easeInOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function easeInOutQuint(x: number): number {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}

/**
 * Counts the number of bits that are set in an integer.
 */
export function bitCount(n: number): number {
    let count = 0;
    while (n) {
        count += n & 1;
        n >>= 1;
    }
    return count;
}
