
export class LeaderboardPeriod {
    public static readonly ALL_TIME = new LeaderboardPeriod("all_time", "All Time");
    public static readonly MONTHLY = new LeaderboardPeriod("monthly", "Monthly");

    static values(): LeaderboardPeriod[] {
        return [
            LeaderboardPeriod.ALL_TIME,
            LeaderboardPeriod.MONTHLY,
        ];
    }

    private readonly id: string;
    private readonly name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    static getByID(id: string): LeaderboardPeriod {
        for (const leaderboardType of LeaderboardPeriod.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        throw new Error("Unknown game mode " + id);
    }

    static getByIDOrNull(id: string | null): LeaderboardPeriod | null {
        if (id === null)
            return null;

        for (const leaderboardType of LeaderboardPeriod.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        return null;
    }
}
