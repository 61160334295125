import { Agent } from "@/ts/royalur/agent/Agent";
import { PiecesAdvancedUtilityFn } from "@/ts/royalur/agent/utility/PiecesAdvancedUtilityFn";
import { LikelihoodAgent } from "@/ts/royalur/agent/LikelihoodAgent";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { SimpleRuleSet } from "@/ts/royalur/rules/simple/SimpleRuleSet";
import { EasyAgent } from "@/ts/business/game/royalur/EasyAgent";
import { MediumAgent } from "@/ts/business/game/royalur/MediumAgent";


type createAgentFnType = (rules: RuleSet) => Agent;


const ONLINE_ONLY: createAgentFnType = () => {
    throw new Error("This bot type can only be played online");
};


function createLikelihoodAgent(
    rules: RuleSet,
    likelihoodThreshold: number,
) {
    if (!(rules instanceof SimpleRuleSet))
        throw new Error("Only standard rule sets are supported for likelihood agents");

    const utilityFn = new PiecesAdvancedUtilityFn(rules);
    return new LikelihoodAgent(rules, utilityFn, likelihoodThreshold);
}


/**
 * The modes guide how games are presented and interacted with.
 */
export class BotType {
    public static readonly EASY = new BotType(
        "easy", "Easy", "Easy Bot",
        "The easy bot leads to more relaxing games, and is good for beginners.",
        false, // expensive
        false, // online
        () => new EasyAgent(),
    );

    public static readonly MEDIUM = new BotType(
        "medium", "Medium", "Medium Bot",
        "The medium bot is challenging to beat.",
        false, // expensive
        false, // online
        rules => new MediumAgent(rules),
    );

    public static readonly HARD = new BotType(
        "hard", "Hard", "Hard Bot",
        "The hard bot is ruthless and very challenging.",
        true, // expensive
        false, // online
        rules => createLikelihoodAgent(rules, 0.0055),
    );

    public static readonly PANDA = new BotType(
        "panda", "Panda", "The Panda",
        "The Panda has achieved mastery over Ur. Even our best will consistently fall to the skill of Panda!",
        false, // expensive
        true, // online
        ONLINE_ONLY,
    );

    static values(): BotType[] {
        return [
            BotType.EASY,
            BotType.MEDIUM,
            BotType.HARD,
            BotType.PANDA,
        ];
    }

    private static createParsingMap(): Record<string, BotType> {
        const map: Record<string, BotType> = {};
        map["Easy"] = BotType.EASY;
        map["Medium"] = BotType.MEDIUM;
        map["Hard"] = BotType.HARD;
        map["Panda"] = BotType.PANDA;

        for (const value of BotType.values()) {
            map[value.id] = value;
        }
        return Object.freeze(map);
    }

    static readonly PARSING_MAP: Record<string, BotType> = BotType.createParsingMap();

    private readonly id: string;
    private readonly name: string;
    private readonly longName: string;
    private readonly desc: string;
    private readonly expensive: boolean;
    private readonly online: boolean;
    private readonly createAgentFn: createAgentFnType;

    constructor(
        id: string,
        name: string,
        longName: string,
        desc: string,
        expensive: boolean,
        online: boolean,
        createAgentFn: createAgentFnType,
    ) {
        this.id = id;
        this.name = name;
        this.longName = longName;
        this.desc = desc;
        this.expensive = expensive;
        this.online = online;
        this.createAgentFn = createAgentFn;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getLongName(): string {
        return this.longName;
    }

    getDesc(): string {
        return this.desc;
    }

    isExpensive(): boolean {
        return this.expensive;
    }

    isOnline(): boolean {
        return this.online;
    }

    createAgent(
        rules: RuleSet,
    ): Agent {
        return this.createAgentFn(rules);
    }

    static getByID(id: string): BotType {
        for (const botType of BotType.values()) {
            if (botType.getID() === id)
                return botType;
        }
        throw new Error(`Unknown bot type ID: ${id}`);
    }

    static getByIDOrNull(id: string | null): BotType | null {
        if (id === null)
            return null;

        for (const botType of BotType.values()) {
            if (botType.getID() === id)
                return botType;
        }
        return null;
    }
}

