
export class Optional<V> {
    private readonly value: V | null;

    constructor(value: V | null) {
        this.value = value;
    }

    isPresent(): boolean {
        return this.value !== null;
    }

    isEmpty(): boolean {
        return this.value === null;
    }

    get(): V {
        if (this.value === null)
            throw new Error("Optional is empty");
        return this.value;
    }

    getOrNull(): V | null {
        return this.value;
    }

    getOrDefault(defaultValue: V) {
        return (this.value !== null ? this.value : defaultValue);
    }

    static of<V>(value: V): Optional<V> {
        if (value === null)
            throw new Error("No value provided");
        return new Optional<V>(value);
    }

    static ofNullable<V>(value: V | null): Optional<V> {
        return new Optional<V>(value);
    }

    static item<V>(value: V): Optional<V> {
        return new Optional<V>(value);
    }

    static empty<V>(): Optional<V> {
        return new Optional<V>(null);
    }
}
