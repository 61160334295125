import { useCallback, useSyncExternalStore } from "react";
import { Rune } from "@/ts/business/Rune";
import { RuneWithEvents } from "@/ts/business/RuneWithEvents";


export function useOptionalRune<V extends Exclude<any, null | undefined>>(
    rune: Rune<V> | RuneWithEvents<V, any> | null | undefined,
): V | null {
    const subscribe = useCallback(
        (onChange: () => void) => {
            if (rune) {
                return rune.subscribe(onChange);
            } else {
                return () => {};
            }
        },
        [rune],
    );
    const get = useCallback(() => {
        return rune ? rune.get() : null;
    }, [rune]);
    return useSyncExternalStore(subscribe, get, get);
}


export function useRune<V extends Exclude<any, null | undefined>>(
    rune: Rune<V> | RuneWithEvents<V, any>
): V {
    const subscribe = useCallback(
        (onChange: () => void) => rune.subscribe(onChange),
        [rune],
    );
    const get = useCallback(() => rune.get(), [rune]);
    return useSyncExternalStore(subscribe, get, get);
}
