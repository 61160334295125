
export class LeaderboardMetric {
    public static readonly ONLINE_WINS = new LeaderboardMetric(
        "online_wins",
        "Online Wins",
    );

    public static readonly PANDA_WINS = new LeaderboardMetric(
        "panda_wins",
        "Panda Wins",
    );

    static values(): LeaderboardMetric[] {
        return [
            LeaderboardMetric.ONLINE_WINS,
            LeaderboardMetric.PANDA_WINS,
        ];
    }

    private readonly id: string;
    private readonly name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    static getByID(id: string): LeaderboardMetric {
        for (const leaderboardType of LeaderboardMetric.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        throw new Error("Unknown game mode " + id);
    }

    static getByIDOrNull(id: string | null): LeaderboardMetric | null {
        if (id === null)
            return null;

        for (const leaderboardType of LeaderboardMetric.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        return null;
    }
}
