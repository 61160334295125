

/**
 * Read an environment variable without it being hardcoded during the build.
 */
export function readOptionalEnv(key: string): string | undefined {
    return process.env[key];
}


/**
 * Read an environment variable without it being hardcoded during the build.
 */
export function readEnv(key: string, defaultValue?: string): string {
    const value = readOptionalEnv(key);
    if (value)
        return value;
    if (defaultValue)
        return defaultValue;

    throw new Error(`Missing ${key} environment variable`);
}
