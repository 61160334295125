import { ListenerStore } from "@/ts/business/ListenerStore";


export type RuneListener<V> = ((value: V, previousValue: V | null) => void);


/**
 * Supports sharing an up-to-date, non-animation, value with
 * the user interface. Animation data should probably use
 * the directive system instead, as it supports queueing.
 */
export class Rune<V extends Exclude<any, null | undefined>> {
    private readonly listeners: ListenerStore<RuneListener<V>>;
    private value: V | null;

    constructor(initialValue?: V | null) {
        this.listeners = new ListenerStore<RuneListener<V>>();
        this.value = initialValue ?? null;
    }

    subscribe(listener: RuneListener<V>): () => void {
        return this.listeners.add(listener);
    }

    get(): V {
        if (this.value === null)
            throw new Error("This rune is empty!");

        return this.value;
    }

    set(value: V, requiredLastValue?: V): boolean {
        const [updated, shouldEmitEvent, previousValue] = this.setWithoutEvent(
            value, requiredLastValue
        );
        if (shouldEmitEvent) {
            this.emitUpdateEvent(value, previousValue);
        }
        return updated;
    }

    map(mapping: (currentValue: V) => V): void {
        const currentValue = this.get();
        const newValue = mapping(currentValue);
        const updated = this.set(newValue, currentValue);
        if (!updated) {
            throw new Error(
                `Unable to update rune. Does the mapping function also update the rune?`
            );
        }
    }

    setAndDeferEvent(value: V, requiredLastValue?: V): () => void {
        const [_, shouldEmitEvent, previousValue] = this.setWithoutEvent(
            value, requiredLastValue
        );
        return (
            shouldEmitEvent
                ? () => this.emitUpdateEvent(value, previousValue)
                : () => {}
        );
    }

    private setWithoutEvent(value: V, requiredLastValue?: V): [boolean, boolean, V | null] {
        const previousValue = this.value;
        if (previousValue === value)
            return [true, false, previousValue];
        if (requiredLastValue !== undefined && previousValue !== requiredLastValue)
            return [false, false, previousValue];

        this.value = value;
        return [true, true, previousValue];
    }

    private emitUpdateEvent(value: V, previousValue: V | null) {
        this.listeners.invoke(value, previousValue);
    }
}
