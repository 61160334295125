import { Milliseconds, Seconds } from "@/ts/util/units";


/**
 * Arbitrary limit used for times that are a long time ago.
 */
export const LONG_TIME_AGO: Milliseconds = -1000;

export function getOrDefault<K extends keyof unknown, V>(
    dict: { [key in K]?: V },
    key: K,
    defaultValue: V,
): V {
    const value = dict[key];
    return (value ? value : defaultValue);
}

export function debounce<C>(
    func: (this: C, ...args: unknown[]) => void,
    time = 100,
): (this: C, ...args: unknown[]) => void {
    let timer: NodeJS.Timeout | null = null;
    return function (this: C, ...args: unknown[]) {
        if (timer !== null) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(() => func.apply(this, args), time);
    };
}

export function getTimeMs(): Milliseconds {
    if (typeof window === "undefined")
        return Date.now();

    return window.performance.now();
}

export function getTimeSeconds(): Seconds {
    return getTimeMs() / 1000.0;
}


//
// GRAPHIC UTILITIES
//

export function renderResource(
    width: number, height: number,
    renderFunction: (
        ctx: CanvasRenderingContext2D,
        canvas: HTMLCanvasElement
    ) => void,
): HTMLCanvasElement {
    if (isNaN(width) || isNaN(height))
        throw new Error("Width and height cannot be NaN, was given " + width + " x " + height);
    if (width < 1 || height < 1)
        throw new Error("Width and height must both be at least 1, was given " + width + " x " + height);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx)
        throw new Error("Missing 2d context for canvas");

    canvas.width = width;
    canvas.height = height;
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";
    renderFunction(ctx, canvas);
    return canvas;
}

export function pathRoundedRect(
    ctx: CanvasRenderingContext2D,
    x: number, y: number, w: number, h: number, r: number) {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
}

export function convertHSVtoRGB(
    h: number, s: number, v: number,
): { r: number; g: number; b: number } {
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    let r, g, b;
    switch (i % 6) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        case 5:
            r = v;
            g = p;
            b = q;
            break;
        default:
            throw new Error("Invalid value of i: " + i);
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255),
    };
}

export function isImageLoaded(image: HTMLImageElement): boolean {
    return image.complete && image.naturalWidth !== 0;
}


export function setIntersection<T>(a: T[], b: T[]): T[] {
    const result: T[] = [];
    for (const valueA of a) {
        let found = false;
        for (const valueB of b) {
            if (valueA === valueB) {
                found = true;
            }
        }
        if (found) {
            result.push(valueA);
        }
    }
    return result;
}
