import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { authForceRefreshCookieName } from "@/app_util/auth/authForceRefresh";
import { useEffect } from "react";
import { useDocument } from "@/app_util/ssrHelper";


/**
 * Allows API routes to tell the client to refresh so that it can
 * clear the router cache...
 */
export function useAuthForceRefresh(router: AppRouterInstance) {
    const document = useDocument();

    let doForceRefresh: boolean = false;
    if (document) {
        const cookies = document.cookie.split("; ");
        const forceRefreshCookie = cookies.find((cookie) => {
            return cookie.startsWith(`${authForceRefreshCookieName}=`);
        });
        doForceRefresh = !!(forceRefreshCookie && forceRefreshCookie.split("=")[1] === "true");
    }

    useEffect(() => {
        if (document && doForceRefresh) {
            document.cookie = `${authForceRefreshCookieName}=;`;
            router.refresh();
        }
    }, [document, doForceRefresh, router]);
}
