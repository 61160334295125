import { ReactNode } from "react";
import { useDocument } from "@/app_util/ssrHelper";
import { createPortal } from "react-dom";


interface BodyPortalProps {
    children?: ReactNode;
}


export function BodyPortal({ children }: BodyPortalProps) {
    const document = useDocument();
    return (
        <>
            {document && createPortal(
                children, document.body,
            )}
        </>
    );
}
