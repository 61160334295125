
export class LeaderboardGrouping {
    public static readonly OVERALL = new LeaderboardGrouping("overall", "Overall",);
    public static readonly FINKEL = new LeaderboardGrouping("finkel", "Finkel",);
    public static readonly BLITZ = new LeaderboardGrouping("blitz", "Blitz",);
    public static readonly MASTERS = new LeaderboardGrouping("masters", "Masters",);

    static values(): LeaderboardGrouping[] {
        return [
            LeaderboardGrouping.OVERALL,
            LeaderboardGrouping.FINKEL,
            LeaderboardGrouping.BLITZ,
            LeaderboardGrouping.MASTERS,
        ];
    }

    private readonly id: string;
    private readonly name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    static getByID(id: string): LeaderboardGrouping {
        for (const leaderboardType of LeaderboardGrouping.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        throw new Error("Unknown game mode " + id);
    }

    static getByIDOrNull(id: string | null): LeaderboardGrouping | null {
        if (id === null)
            return null;

        for (const leaderboardType of LeaderboardGrouping.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        return null;
    }
}
