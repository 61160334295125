
const backupStorage: Map<string, string> = new Map<string, string>();


export function readBackupStorage(key: string): string | null {
    const value = backupStorage.get(key);
    return (value !== undefined ? value : null);
}


export function writeBackupStorage(key: string, value: string | null): boolean {
    if (value !== null) {
        backupStorage.set(key, value);
    } else {
        backupStorage.delete(key);
    }
    return true;
}


export function readSessionStorage(key: string): string | null {
    try {
        if (typeof sessionStorage === "undefined" || !sessionStorage)
            return null;

        return sessionStorage.getItem(key);
    } catch (_) {
        return null;
    }
}


export function writeSessionStorage(key: string, value: string | null): boolean {
    try {
        if (typeof sessionStorage === "undefined" || !sessionStorage)
            return false;

        if (value !== null) {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.removeItem(key);
        }
        return true;
    } catch (_) {
        return false;
    }
}


export function readLocalStorage(key: string): string | null {
    try {
        if (typeof localStorage === "undefined" || !localStorage)
            return null;

        return localStorage.getItem(key);
    } catch (_) {
        return null;
    }
}


export function writeLocalStorage(key: string, value: string | null): boolean {
    try {
        if (typeof localStorage === "undefined" || !localStorage)
            return false;

        if (value !== null) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
        return true;
    } catch (_) {
        return false;
    }
}


export function readStorage(key: string): string | null {
    const localValue = readLocalStorage(key);
    if (localValue !== null)
        return localValue;

    const sessionValue = readSessionStorage(key);
    if (sessionValue !== null)
        return sessionValue;

    return readBackupStorage(key);
}


export function writeStorage(key: string, value: string | null): boolean {
    const wroteLocal = writeLocalStorage(key, value);
    const wroteSession = writeSessionStorage(key, value);
    const wroteBackup = writeBackupStorage(key, value);
    return wroteLocal || wroteSession || wroteBackup;
}


export function readStorageJSON(key: string): object | null {
    const json = readStorage(key);
    if (!json)
        return null;

    try {
        const parsed = JSON.parse(json);
        if (typeof parsed !== "object")
            return null;

        return parsed;
    } catch (_) {
        return null;
    }
}


export function writeStorageJSON(key: string, value: object): boolean {
    try {
        const json = JSON.stringify(value);
        return writeStorage(key, json);
    } catch (_) {
        return false;
    }
}
