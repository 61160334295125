

/**
 * Represents the players of a game.
 */
export class PlayerType {

    /**
     * The light player. Following chess, the light player moves first.
     */
    public static readonly LIGHT: PlayerType = new PlayerType("L", "Light");

    /**
     * The dark player. Following chess, the dark player moves second.
     */
    public static readonly DARK: PlayerType = new PlayerType("D", "Dark");

    static values(): PlayerType[] {
        return [
            PlayerType.LIGHT,
            PlayerType.DARK,
        ];
    }

    private readonly character: string;
    private readonly name: string;

    constructor(character: string, name: string) {
        this.character = character;
        this.name = name;
    }

    /**
     * Gets a constant character representing the player.
     * This character will never change.
     */
    getChar(): string {
        return this.character;
    }

    getName(): string {
        return this.name;
    }

    /**
     * Retrieves the Player representing the other player.
     */
    getOtherPlayer(): PlayerType {
        switch (this) {
            case PlayerType.LIGHT:
                return PlayerType.DARK;
            case PlayerType.DARK:
                return PlayerType.LIGHT;
            default:
                throw new Error("Unsupported player " + this.name);
        }
    }

    /**
     * Converts player to a single character that can be used
     * to textually represent a piece.
     */
    static toChar(player: PlayerType | null): string {
        return player !== null ? player.character : ".";
    }

    static getByChar(character: string): PlayerType {
        for (const playerType of PlayerType.values()) {
            if (playerType.getChar() === character)
                return playerType;
        }
        throw new Error(`Unknown player character '${character}'`);
    }

    static getByCharOrNull(character: string | null): PlayerType | null {
        if (character === null)
            return null;

        for (const playerType of PlayerType.values()) {
            if (playerType.getChar() === character)
                return playerType;
        }
        return null;
    }
}
