import { AnalyticsEventType } from "@/ts/business/analytics/AnalyticsEventType";


/**
 * An event that can be recorded.
 */
export class AnalyticsEvent {
    private readonly type: AnalyticsEventType;
    private readonly action: string;
    private readonly name?: string;
    private readonly value?: number;

    constructor(type: AnalyticsEventType, action: string, name?: string, value?: number) {
        if (value !== undefined && name === undefined)
            throw new Error("Cannot provide an event value without a name");

        this.type = type;
        this.action = action;
        this.name = name;
    }

    getType(): AnalyticsEventType {
        return this.type;
    }

    getAction(): string {
        return this.action;
    }

    getName(): string | undefined {
        return this.name;
    }

    getValue(): number | undefined {
        return this.value;
    }
}
