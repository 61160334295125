import { z } from "zod";
import { DBCompleteUser, DBRichGame, DBRichGamePlayer } from "@/ts/db/model/GameModel";
import {
    DBGameAnalysis,
    DBMonthlyUserStats,
    DBNameColour,
    DBUser,
    DBUserGamePreferences,
    DBUserStats,
} from "@/ts/db/db_schema";
import { BotType } from "@/ts/business/game/BotType";
import { LeaderboardType } from "@/ts/business/api/leaderboard/LeaderboardType";
import { LeaderboardPeriod } from "@/ts/business/api/leaderboard/LeaderboardPeriod";
import { LeaderboardGrouping } from "@/ts/business/api/leaderboard/LeaderboardGrouping";
import { LeaderboardMetric } from "@/ts/business/api/leaderboard/LeaderboardMetric";


export const APIIncompleteUser = z.object({
    publicID: z.string(),
    username: z.ostring(),
    displayName: z.ostring(),
    createdAt: z.coerce.date(),
});
export type APIIncompleteUser = z.infer<typeof APIIncompleteUser>;


export const APIUser = z.object({
    publicID: z.string(),
    username: z.string(),
    displayName: z.string(),
    hasRoleMembur: z.boolean(),
    hasRoleTeam: z.boolean(),
    hasRoleAdmin: z.boolean(),
    createdAt: z.coerce.date(),
});
export type APIUser = z.infer<typeof APIUser>;


export type APIOptionalUser = APIUser | APIIncompleteUser | null;


export function createAPIUser(dbUser: DBCompleteUser | DBUser): APIUser {
    if (!dbUser.username)
        throw new Error("User is missing a username");
    if (!dbUser.display_name)
        throw new Error("User is missing a display_name");

    return APIUser.parse({
        publicID: dbUser.public_id,
        username: dbUser.username,
        displayName: dbUser.display_name,
        hasRoleMembur: dbUser.memburship_end_at !== null,
        hasRoleTeam: dbUser.has_role_team,
        hasRoleAdmin: dbUser.has_role_admin,
        createdAt: dbUser.created_at,
    });
}


export function toCompleteUser(user: APIOptionalUser): APIUser | null {
    const result = APIUser.safeParse(user);
    return result.success ? user as APIUser : null;
}


export function toIncompleteUser(user: APIOptionalUser): APIIncompleteUser | null {
    const completeUser = toCompleteUser(user);
    return (completeUser === null ? user as APIIncompleteUser | null : null);
}


export const APINameColour = z.union([
    z.literal("gray"),
    z.literal("red"),
    z.literal("green"),
    z.literal("blue"),
    z.literal("gold"),
    z.literal("orange"),
    z.literal("pink"),
]);
export type APINameColour = z.infer<typeof APINameColour>;


export const apiNameColours: APINameColour[] = [
    "gray",
    "red",
    "green",
    "blue",
    "gold",
    "orange",
    "pink",
];

export function createAPINameColour(dbColour: number | DBNameColour): APINameColour {
    if (typeof dbColour === "number") {
        dbColour = DBNameColour.findByID(dbColour, DBNameColour.GRAY);
    }
    return APINameColour.parse(dbColour.getApiID());
}


export const APIDiceStyle = z.union([
    z.literal("default"),
    z.literal("fast"),
    z.literal("dramatic"),
]);
export type APIDiceStyle = z.infer<typeof APIDiceStyle>;


export const APIPublicGamePreferences = z.object({
    nameColour: APINameColour,
    disabledReactions: z.boolean(),
});
export type APIPublicGamePreferences = z.infer<typeof APIPublicGamePreferences>;


export const APIGamePreferences = z.object({
    soundVolume: z.number(),
    musicVolume: z.number(),
    hideMusicControls: z.boolean(),
    autoSelectSingleMoveOption: z.boolean(),
    showMoveGuidelines: z.boolean(),
    diceStyle: APIDiceStyle,
    autoDiceRolls: z.boolean(),
}).merge(APIPublicGamePreferences);
export type APIGamePreferences = z.infer<typeof APIGamePreferences>;


export const defaultGamePreferences: APIGamePreferences = {
    nameColour: "gray",
    disabledReactions: false,
    soundVolume: 75.0,
    musicVolume: 75.0,
    hideMusicControls: false,
    autoSelectSingleMoveOption: true,
    showMoveGuidelines: true,
    diceStyle: "default",
    autoDiceRolls: false,
};


export function createAPIGamePreferences(
    dbPreferences: DBUserGamePreferences,
): APIGamePreferences {
    return {
        nameColour: createAPINameColour(dbPreferences.name_colour),
        disabledReactions: dbPreferences.disabled_reactions,
        soundVolume: dbPreferences.sound_volume,
        musicVolume: dbPreferences.music_volume,
        hideMusicControls: dbPreferences.hide_music_controls,
        autoSelectSingleMoveOption: dbPreferences.auto_select_single_move_option,
        showMoveGuidelines: dbPreferences.show_move_guidelines,
        diceStyle: APIDiceStyle.parse(dbPreferences.dice_style),
        autoDiceRolls: dbPreferences.auto_dice_rolls,
    };
}


export function restrictToPublicGamePreferences(
    pref: APIGamePreferences,
): APIPublicGamePreferences {
    return {
        nameColour: pref.nameColour,
        disabledReactions: pref.disabledReactions,
    };
}


export const APIBaselineStats = z.object({
    online_finkel_wins: z.number(),
    online_finkel_losses: z.number(),
    online_blitz_wins: z.number(),
    online_blitz_losses: z.number(),
    online_masters_wins: z.number(),
    online_masters_losses: z.number(),
    online_wins: z.number(),
    online_losses: z.number(),
    online_games: z.number(),

    online_resigned_games: z.number(),
    online_left_games: z.number(),
    online_left_b4_begin_games: z.number(),

    friend_finkel_wins: z.number(),
    friend_finkel_losses: z.number(),
    friend_blitz_wins: z.number(),
    friend_blitz_losses: z.number(),
    friend_masters_wins: z.number(),
    friend_masters_losses: z.number(),
    friend_wins: z.number(),
    friend_losses: z.number(),
    friend_games: z.number(),

    computer_finkel_wins: z.number(),
    computer_finkel_losses: z.number(),
    computer_blitz_wins: z.number(),
    computer_blitz_losses: z.number(),
    computer_masters_wins: z.number(),
    computer_masters_losses: z.number(),
    computer_wins: z.number(),
    computer_losses: z.number(),
    computer_games: z.number(),

    panda_finkel_wins: z.number(),
    panda_finkel_losses: z.number(),
    panda_blitz_wins: z.number(),
    panda_blitz_losses: z.number(),
    panda_masters_wins: z.number(),
    panda_masters_losses: z.number(),
    panda_wins: z.number(),
    panda_losses: z.number(),
    panda_games: z.number(),

    custom_games: z.number(),
    wins: z.number(),
    losses: z.number(),
    games: z.number(),
});
export type APIBaselineStats = z.infer<typeof APIBaselineStats>;


export function createAPIBaselineStats(
    dbStats: DBUserStats | DBMonthlyUserStats,
): APIBaselineStats {

    const online_wins = (
        dbStats.online_finkel_wins
        + dbStats.online_blitz_wins
        + dbStats.online_masters_wins
    );
    const online_losses = (
        dbStats.online_finkel_losses
        + dbStats.online_blitz_losses
        + dbStats.online_masters_losses
    );
    const online_games = online_wins + online_losses;

    const friend_wins = (
        dbStats.friend_finkel_wins
        + dbStats.friend_blitz_wins
        + dbStats.friend_masters_wins
    );
    const friend_losses = (
        dbStats.friend_finkel_losses
        + dbStats.friend_blitz_losses
        + dbStats.friend_masters_losses
    );
    const friend_games = friend_wins + friend_losses;

    const computer_wins = (
        dbStats.computer_finkel_wins
        + dbStats.computer_blitz_wins
        + dbStats.computer_masters_wins
    );
    const computer_losses = (
        dbStats.computer_finkel_losses
        + dbStats.computer_blitz_losses
        + dbStats.computer_masters_losses
    );
    const computer_games = computer_wins + computer_losses;

    const panda_wins = (
        dbStats.panda_finkel_wins
        + dbStats.panda_blitz_wins
        + dbStats.panda_masters_wins
    );
    const panda_losses = (
        dbStats.panda_finkel_losses
        + dbStats.panda_blitz_losses
        + dbStats.panda_masters_losses
    );
    const panda_games = panda_wins + panda_losses;

    const wins = online_wins + friend_wins + computer_wins;
    const losses = online_losses + friend_losses + computer_losses;
    const games = wins + losses + dbStats.custom_games;

    return {
        online_finkel_wins: dbStats.online_finkel_wins,
        online_finkel_losses: dbStats.online_finkel_losses,
        online_blitz_wins: dbStats.online_blitz_wins,
        online_blitz_losses: dbStats.online_blitz_losses,
        online_masters_wins: dbStats.online_masters_wins,
        online_masters_losses: dbStats.online_masters_losses,
        online_wins, online_losses, online_games,

        online_resigned_games: dbStats.online_resigned_games,
        online_left_games: dbStats.online_left_games,
        online_left_b4_begin_games: dbStats.online_left_b4_begin_games,

        friend_finkel_wins: dbStats.friend_finkel_wins,
        friend_finkel_losses: dbStats.friend_finkel_losses,
        friend_blitz_wins: dbStats.friend_blitz_wins,
        friend_blitz_losses: dbStats.friend_blitz_losses,
        friend_masters_wins: dbStats.friend_masters_wins,
        friend_masters_losses: dbStats.friend_masters_losses,
        friend_wins, friend_losses, friend_games,

        computer_finkel_wins: dbStats.computer_finkel_wins,
        computer_finkel_losses: dbStats.computer_finkel_losses,
        computer_blitz_wins: dbStats.computer_blitz_wins,
        computer_blitz_losses: dbStats.computer_blitz_losses,
        computer_masters_wins: dbStats.computer_masters_wins,
        computer_masters_losses: dbStats.computer_masters_losses,
        computer_wins, computer_losses, computer_games,

        panda_finkel_wins: dbStats.panda_finkel_wins,
        panda_finkel_losses: dbStats.panda_finkel_losses,
        panda_blitz_wins: dbStats.panda_blitz_wins,
        panda_blitz_losses: dbStats.panda_blitz_losses,
        panda_masters_wins: dbStats.panda_masters_wins,
        panda_masters_losses: dbStats.panda_masters_losses,
        panda_wins, panda_losses, panda_games,

        custom_games: dbStats.custom_games,
        wins, losses, games,
    };
}


export const APIUserStats = z.object({
    moves: z.number(),
    captures: z.number(),
    lands_on_rosettes: z.number(),
    scored_pieces: z.number(),
    longest_streak: z.number(),
    longest_streak_game: z.nullable(z.string()),

    roll_4d2_0_count: z.number(),
    roll_4d2_1_count: z.number(),
    roll_4d2_2_count: z.number(),
    roll_4d2_3_count: z.number(),
    roll_4d2_4_count: z.number(),
    roll_4d2_count: z.number(),
}).merge(APIBaselineStats);
export type APIUserStats = z.infer<typeof APIUserStats>;


export function createAPIUserStats(
    dbUserStats: DBUserStats,
): APIUserStats {
    return {
        moves: dbUserStats.moves,
        captures: dbUserStats.captures,
        lands_on_rosettes: dbUserStats.lands_on_rosettes,
        scored_pieces: dbUserStats.scored_pieces,
        longest_streak: dbUserStats.longest_streak,
        longest_streak_game: dbUserStats.longest_streak_game,

        roll_4d2_0_count: dbUserStats.roll_4d2_0_count,
        roll_4d2_1_count: dbUserStats.roll_4d2_1_count,
        roll_4d2_2_count: dbUserStats.roll_4d2_2_count,
        roll_4d2_3_count: dbUserStats.roll_4d2_3_count,
        roll_4d2_4_count: dbUserStats.roll_4d2_4_count,
        roll_4d2_count: dbUserStats.roll_4d2_count,

        ...createAPIBaselineStats(dbUserStats),
    };
}


export const APILeaderboardType = z.object({
    period: z.string(),
    yearMonth: z.nullable(z.number()),
    grouping: z.string(),
    metric: z.string(),
});
export type APILeaderboardType = z.infer<typeof APILeaderboardType>;


export function createAPILeaderboardType(
    leaderboardType: LeaderboardType,
): APILeaderboardType {
    return {
        period: leaderboardType.period.getID(),
        yearMonth: leaderboardType.yearMonth,
        grouping: leaderboardType.grouping.getID(),
        metric: leaderboardType.metric.getID(),
    };
}


export function convertToLeaderboardType(
    apiLeaderboardType: APILeaderboardType,
): LeaderboardType {
    return new LeaderboardType(
        LeaderboardPeriod.getByID(apiLeaderboardType.period),
        apiLeaderboardType.yearMonth,
        LeaderboardGrouping.getByID(apiLeaderboardType.grouping),
        LeaderboardMetric.getByID(apiLeaderboardType.metric),
    );
}


export const APIGamePlayer = z.object({
    user: z.optional(APIUser),
    userPreferences: z.optional(APIPublicGamePreferences),
    botID: z.ostring(),
});
export type APIGamePlayer = z.infer<typeof APIGamePlayer>;


export function createAPIGamePlayer(
    dbGamePlayer: DBRichGamePlayer,
): APIGamePlayer {
    const dbUser = dbGamePlayer.user;
    const botID = dbGamePlayer.bot_id;
    if (dbUser) {
        const dbPref = dbGamePlayer.userPreferences;
        const user = createAPIUser(dbUser);
        const pref = (dbPref ? createAPIGamePreferences(dbPref) : undefined);
        const publicPref = (pref ? restrictToPublicGamePreferences(pref) : undefined);
        return {
            user: user,
            userPreferences: publicPref,
        };
    }
    if (!botID)
        throw new Error("Missing both a user and a bot_id");

    return { botID };
}


export function createOptionalAPIGamePlayer(
    dbGamePlayer: DBRichGamePlayer | null,
): APIGamePlayer | undefined {
    return (dbGamePlayer ? createAPIGamePlayer(dbGamePlayer) : undefined);
}


export function getGamePlayerDisplayName(player: APIGamePlayer | undefined): string {
    if (!player)
        return "Anonymous";

    const { user, botID } = player;
    if (user)
        return user.displayName;
    if (botID)
        return BotType.getByID(botID).getLongName();

    throw new Error("player does not have enough information to determine name!");
}


export const APIGameAnalysisOverview = z.object({
    lightMoveCount: z.number(),
    darkMoveCount: z.number(),
    lightMeanRollDelta: z.number(),
    darkMeanRollDelta: z.number(),
    lightMeanMoveDelta: z.number(),
    darkMeanMoveDelta: z.number(),
    createdAt: z.coerce.date(),
});
export type APIGameAnalysisOverview = z.infer<typeof APIGameAnalysisOverview>;


export function createAPIGameAnalysisOverview(dbGameAnalysis: DBGameAnalysis): APIGameAnalysisOverview {
    return {
        lightMoveCount: dbGameAnalysis.light_move_count,
        darkMoveCount: dbGameAnalysis.dark_move_count,
        lightMeanRollDelta: dbGameAnalysis.light_mean_roll_delta,
        darkMeanRollDelta: dbGameAnalysis.dark_mean_roll_delta,
        lightMeanMoveDelta: dbGameAnalysis.light_mean_move_delta,
        darkMeanMoveDelta: dbGameAnalysis.dark_mean_move_delta,
        createdAt: dbGameAnalysis.created_at,
    };
}


export function createOptionalAPIGameAnalysisOverview(
    dbGameAnalysis: DBGameAnalysis | null,
): APIGameAnalysisOverview | undefined {
    return (dbGameAnalysis ? createAPIGameAnalysisOverview(dbGameAnalysis) : undefined);
}


export const APIGame = z.object({
    publicID: z.string(),
    gameMode: z.string(),
    gameTypeID: z.string(),
    botTypeID: z.nullable(z.string()),
    endReasonID: z.string(),
    isEndingPlayerLight: z.nullable(z.boolean()),
    lightPlayer: z.optional(APIGamePlayer),
    darkPlayer: z.optional(APIGamePlayer),
    didLightWin: z.nullable(z.boolean()),
    contents: z.optional(z.any()),
    startedAt: z.coerce.date(),
    endedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
    analysis: z.optional(APIGameAnalysisOverview),
});
export type APIGame = z.infer<typeof APIGame>;


export function createAPIGame(dbRichGame: DBRichGame): APIGame {
    const dbGame = dbRichGame.game;
    return {
        publicID: dbGame.public_id,
        gameMode: dbGame.game_mode,
        gameTypeID: dbGame.settings_id,
        botTypeID: dbGame.bot_type_id,
        endReasonID: dbGame.end_reason_id,
        isEndingPlayerLight: dbGame.is_ending_player_light,
        lightPlayer: createOptionalAPIGamePlayer(dbRichGame.light_player),
        darkPlayer: createOptionalAPIGamePlayer(dbRichGame.dark_player),
        didLightWin: dbGame.did_light_win,
        contents: dbGame.contents,
        startedAt: dbGame.started_at,
        endedAt: dbGame.ended_at,
        createdAt: dbGame.created_at,
        analysis: createOptionalAPIGameAnalysisOverview(dbRichGame.game_analysis),
    };
}
