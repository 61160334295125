"use client";

import { createContext, ReactNode, useContext, useEffect } from "react";
import {
    APIIncompleteUser,
    APIOptionalUser,
    APIUser,
    toCompleteUser,
    toIncompleteUser,
} from "@/ts/business/api/api_schema";
import { matomo } from "@/app_components/Matomo";
import { Rune } from "@/ts/business/Rune";
import { useRune } from "@/app_util/useRune";
import { Optional } from "@/ts/util/Optional";
import { useRouter } from "next/navigation";
import { useAuthForceRefresh } from "@/app_util/auth/useAuthForceRefresh";


// The context allows useUser to work straight away within the context (no useEffect delay).
// The holder allows useUser to work outside the context (with useEffect delay).
const UserContext = createContext<APIUser | null>(null);
const theUserHolder = new Rune<Optional<APIUser>>(Optional.empty());


// The context allows useUser to work straight away within the context (no useEffect delay).
// The holder allows useUser to work outside the context (with useEffect delay).
const IncompleteUserContext = createContext<APIIncompleteUser | null>(null);
const theIncompleteUserHolder = new Rune<Optional<APIIncompleteUser>>(Optional.empty());


export function useOptionalUser(): APIUser | null {
    const ctxUser = useContext(UserContext);
    const holderUser = useRune(theUserHolder).getOrNull();
    return ctxUser ?? holderUser;
}


export function useOptionalIncompleteUser(): APIIncompleteUser | null {
    const ctxUser = useContext(IncompleteUserContext);
    const holderUser = useRune(theIncompleteUserHolder).getOrNull();
    return ctxUser ?? holderUser;
}


interface UserOrIncompleteUser {
    user: APIUser | null;
    incompleteUser: APIIncompleteUser | null;
}


export function useUserOrIncompleteUser(): UserOrIncompleteUser {
    const incompleteUser = useOptionalIncompleteUser();
    const user = useOptionalUser();
    return { incompleteUser, user };
}


export function useUser(): APIUser {
    const user = useOptionalUser();
    if (!user)
        throw new Error("No user available!");

    return user;
}


interface ClientUserProviderProps {
    children?: ReactNode;
    user: APIOptionalUser;
}


export function UserProvider({ children, user }: ClientUserProviderProps) {
    const router = useRouter();
    useAuthForceRefresh(router);

    const completeUser = toCompleteUser(user);
    const incompleteUser = toIncompleteUser(user);
    matomo.recordUser(completeUser);

    const existingIncompleteUser = theIncompleteUserHolder.get().getOrNull();
    const existingUser = theUserHolder.get().getOrNull();
    useEffect(() => {
        if (existingIncompleteUser !== incompleteUser) {
            theIncompleteUserHolder.set(Optional.ofNullable(incompleteUser));
        }
        if (existingUser !== completeUser) {
            theUserHolder.set(Optional.ofNullable(completeUser));
        }
    }, [
        existingIncompleteUser, incompleteUser,
        existingUser, completeUser,
    ]);

    return (
        <IncompleteUserContext.Provider value={incompleteUser}>
            <UserContext.Provider value={completeUser}>
                {children}
            </UserContext.Provider>
        </IncompleteUserContext.Provider>
    );
}
